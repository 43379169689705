/*
 * @Date: 2021-09-29 10:21:46
 * @Author: Jeason
 * @LastEditors: Jeason
 * @LastEditTime: 2021-09-29 10:22:33
 * @Description: common数据
 * @FilePath: \sass_web\maintenance_expert\src\store\common\getters.js
 */

export default {
};
