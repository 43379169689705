/*
 * @Date: 2021-09-29 10:21:46
 * @Author: Jeason
 * @LastEditors: Jeason
 * @LastEditTime: 2021-09-29 10:23:27
 * @Description: 通用数据事件
 * @FilePath: \sass_web\maintenance_expert\src\store\common\mutations.js
 */

export default {
  changeDefaultActive (state, defaultActiveName) {
    state.defaultActiveName = defaultActiveName;
  },
  changeBreadLists (state, breadLists) {
    state.breadLists = breadLists;
  },
};
