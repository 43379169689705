
let toThousands = (currentNum) => {
  if (Number(currentNum).toString() !== 'NaN') {
    // 添加千分符
    let tempNum = Number(currentNum).toFixed(2).toString().replace(/\d+/, (num) => { // 先提取整数部分
      return num.replace(/(\d)(?=(\d{3})+$)/g, ($1) => {
        return $1 + ',';
      });
    });
    if (!tempNum.includes('.')) {
      tempNum += '.00';
    }
    return tempNum;
  }
  return '';
};
export default toThousands;
