/*
 * @Date: 2021-09-29 10:21:46
 * @Author: Jeason
 * @LastEditors: Jeason
 * @LastEditTime: 2021-09-29 10:23:18
 * @Description: 通用数据
 * @FilePath: \sass_web\maintenance_expert\src\store\common\state.js
 */

export default {
  closeOnClickModal: false,
  appendToBody: true,
  destroyOnClose: true,
  defaultActiveName: sessionStorage.getItem('defaultActiveName') || '',
  breadLists: JSON.parse(sessionStorage.getItem('breadLists')) || [],
};
