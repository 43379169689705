import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

Vue.use(VueRouter);

const routes = [
  {
    path: '/home',
    name: 'HOME',
    component: () => import('@/components/common/layout/index.vue'),
    redirect: {name: 'USER_INFO'},
    children: [
      {
        path: '/userInfo',
        name: 'USER_INFO',
        component: () => import('@/views/userCenter/index.vue'),
        meta: {
          title: '用户中心',
        },
      },
      {
        path: '/company',
        name: 'Company',
        component: () => import('@/views/company/index.vue'),
        meta: {
          title: '我的企业',
        },
      },
      {
        path: '/staff',
        name: 'Staff',
        component: () => import('@/views/staff/index.vue'),
        meta: {
          title: '员工授权',
        },
      },
      {
        path: '/deliveryRecord',
        name: 'DeliveryRecord',
        component: () => import('@/views/salesReconciliation/deliveryRecord.vue'),
        meta: {
          title: '出库记录',
        },
      },
      {
        path: '/returnRecord',
        name: 'ReturnRecord',
        component: () => import('@/views/salesReconciliation/returnRecord.vue'),
        meta: {
          title: '退货记录',
        },
      },
      {
        path: '/salesInvoicingRecord',
        name: 'SalesInvoicingRecord',
        component: () => import('@/views/salesInvoicing/index.vue'),
        meta: {
          title: '销售开票记录',
        },
      },
      {
        path: '/personalCenter',
        name: 'PersonalCenter',
        component: () => import('@/views/PersonalCenter/index.vue'),
        meta: {
          title: '个人中心',
        },
      },
      {
        path: '/userInfo/detail',
        name: 'DetailAd',
        component: () => import('@/views/userCenter/detail.vue'),
        meta: {
          title: '公告详情',
        },
      },
      {
        path: '/product',
        name: 'Product',
        component: () => import('@/views/product/index.vue'),
        meta: {
          title: '上架商品',
        },
      },
      {
        path: '/company/detail',
        name: 'DetailCompany',
        component: () => import('@/views/company/detail.vue'),
        meta: {
          title: '企业详情',
        },
      },
      {
        path: '/salesInvoicingDetail',
        name: 'SalesInvoicingDetail',
        component: () => import('@/views/salesInvoicing/detail.vue'),
        meta: {
          title: '销售开票详情',
        },
      },
      {
        path: '/saleContract',
        name: 'SaleContract',
        component: () => import('@/views/saleContract/index.vue'),
        meta: {
          title: '销售合同',
        },
      },
      {
        path: '/saleContract/detail',
        name: 'SaleContractDetail',
        component: () => import('@/views/saleContract/detail.vue'),
        meta: {
          title: '销售合同详情',
        },
        props: route => ({
          id: route.query.id,
          type: route.query.type,
        }),
      },
      {
        path: '/saleContract/review',
        name: 'SaleContractReview',
        component: () => import('@/views/saleContract/detail.vue'),
        meta: {
          title: '销售合同审核',
        },
        props: route => ({
          id: route.query.id,
          type: route.query.type,
        }),
      },
      {
        path: '/pickUpOrders',
        name: 'PickUpOrders',
        component: () => import('@/views/pickUpOrders/index.vue'),
        meta: {
          title: '提货订单',
        },
      },
      {
        path: '/pickUpOrders/ship',
        name: 'PickUpOrdersShip',
        component: () => import('@/views/pickUpOrders/ship.vue'),
        meta: {
          title: '订单发货',
        },
        props: route => ({
          id: route.query.id,
        }),
      },
      {
        path: '/pickUpOrders/detail',
        name: 'PickUpOrdersDetail',
        component: () => import('@/views/pickUpOrders/detail.vue'),
        meta: {
          title: '订单详情',
        },
        props: route => ({
          orderCode: route.query.orderCode,
          id: route.query.id,
        }),
      },
      {
        path: '/invoiceMgr',
        name: 'InvoiceMgr',
        component: () => import('@/views/invoiceMgr/index.vue'),
        meta: {
          title: '发货单管理',
        },
      },
      {
        path: '/invoiceMgr/detail',
        name: 'InvoiceMgrDetail',
        component: () => import('@/views/invoiceMgr/detail.vue'),
        meta: {
          title: '发货详情',
        },
        props: route => ({
          id: route.query.id,
        }),
      },
      {
        path: '/service',
        name: 'Service',
        component: () => import('@/views/service/afterSales/index.vue'),
        meta: {
          title: '销售售后',
        },
      },
      {
        path: '/service/detail',
        name: 'ServiceDetail',
        component: () => import('@/views/service/afterSales/detail.vue'),
        meta: {
          title: '销售售后详情',
        },
        props: route => ({
          id: route.query.id,
          type: route.query.type,
          returnType: route.query.returnType,
        }),
      },
      {
        path: '/service/review',
        name: 'ServiceReview',
        component: () => import('@/views/service/afterSales/detail.vue'),
        meta: {
          title: '销售售后审核',
        },
        props: route => ({
          id: route.query.id,
          type: route.query.type,
          returnType: route.query.returnType,
        }),
      },
      {
        path: '/service/return',
        name: 'ServiceReturn',
        component: () => import('@/views/service/receiveReturns/index.vue'),
        meta: {
          title: '售后退货收货',
        },
      },
      {
        path: '/service/returnDetail',
        name: 'ServiceReturnDetail',
        component: () => import('@/views/service/receiveReturns/detail.vue'),
        meta: {
          title: '退货确认详情',
        },
        props: route => ({
          id: route.query.id,
        }),
      },
      {
        path: '/service/accountStatement/list',
        name: 'AccountStatement',
        component: () => import('@/views/salesReconciliation/accountStatement/index.vue'),
        meta: {
          title: '对账单',
        },
      },
      {
        path: '/service/accountStatement/detail',
        name: 'DetailAccountStatement',
        component: () => import('@/views/salesReconciliation/accountStatement/detail.vue'),
        meta: {
          title: '对账单详情',
        },
      },
    ],
    meta: {
      title: '首页',
    },
  },
  {
    path: '/',
    name: 'Home',
    redirect: {path: '/login'},
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import('../views/Login.vue'),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // let isLogin = !!localStorage.getItem('userInfo') && !!localStorage.getItem('token');
  // if (to.name === 'LOGIN') {
  //   if (isLogin) {
  //     next({
  //       name: 'HOME',
  //     });
  //     return;
  //   } else {
  //     NProgress.start();
  //     next();
  //     return;
  //   }
  // }
  // if (!isLogin) {
  //   next({
  //     name: 'LOGIN',
  //   });
  //   return;
  // }
  // document.title = to.meta.title || '售后宝';
  NProgress.start();
  next();
});
router.afterEach(() => {
  NProgress.done();
});

export default router;
