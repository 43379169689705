export default {
  changeUserInfo (context, userInfo) {
    sessionStorage.setItem('currentUserInfo', JSON.stringify(userInfo));
    context.commit('changeUserInfo', userInfo);
  },
  changeAllCompanyLists (context, allCompanyLists) {
    sessionStorage.setItem('allCompanyLists', JSON.stringify(allCompanyLists));
    context.commit('changeAllCompanyLists', allCompanyLists);
  },
  changeCurrentSupplierId (context, currentSupplierId) {
    localStorage.setItem('currentSupplierId', currentSupplierId);
    context.commit('changeCurrentSupplierId', currentSupplierId);
  },
};
