/*
 * @Date: 2021-09-27 16:47:47
 * @Author: Jeason
 * @LastEditors: Jeason
 * @LastEditTime: 2021-09-27 16:47:50
 * @Description: 项目中共用接口
 * @FilePath: \maintenancef:\sass_web\maintenance_expert\src\api\common.js
 */
import server from '@/request';
export default {
  getQinNiuToken (params) {
    return server.post('/xiaozi-seller/wireless/system/getUploadToken', params);
  },
};
