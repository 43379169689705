export default {
  changeUserInfo (state, userInfo) {
    state.userInfo = userInfo;
  },
  changeAllCompanyLists (state, allCompanyLists) {
    state.allCompanyLists = allCompanyLists;
  },
  changeCurrentSupplierId (state, currentSupplierId) {
    state.currentSupplierId = currentSupplierId;
  },
};
