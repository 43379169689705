export default {
  menuTree (state) {
    return state.menuTree;
  },
  menuIdMap (state) {
    return state.menuIdMap;
  },
  menuUrlMap (state) {
    return state.menuUrlMap;
  },
  actionPermissionMap (state) {
    return state.actionPermissionMap;
  },
};

