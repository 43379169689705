export default {
  UPDATE_MENU_TREE (state, menuTree) {
    state.menuTree = menuTree;
  },
  UPDATE_MENU_ID_MAP (state, map) {
    state.menuIdMap = map;
  },
  UPDATE_MENU_URL_MAP (state, map) {
    state.menuUrlMap = map;
  },
  UPDATE_ACTION_PERMISSION (state, map) {
    state.actionPermissionMap = map;
  },
};

