<template>
    <vxe-grid
        resizable
        autoResize
        ref="vxeTable"
        border="full"
        :size="size"
        :height="height"
        :max-height="maxHeight"
        :data="tableData"
        :row-id="rowKey"
        highlight-current-row
        highlight-hover-row
        highlight-current-column
        highlight-hover-column
        :scroll-y="scrollY"
        :columns="columns"
        :checkbox-config="checkboxConfig"
        :radio-config="radioConfig"
        :seq-config="seqConfig"
        @sort-change="handleSortChanged"
        @checkbox-change="handleCheckboxChanged"
        @checkbox-all="handleCheckboxAllChecked"
        @toolbar-button-click="handleToolbarButtonClick"
        :toolbarConfig="toolbarConfig"
        :customConfig="customConfig"
        :sort-config="defaultSort"
        style="width: 100%">
        <!-- <template slot="empty">
          <el-empty :image-size="100" description="暂无数据"></el-empty>
        </template> -->
        <!-- <template v-for="(column,index) in columnList">
          <vxe-table-column v-if="column.type && column.type === 'index'"
            :key="`${index}--${column.prop}`"
            type="seq"
            :align="column.align||'center'"
            :fixed="column.fixed"
            :title="column.label || ''"
            width="60px"
          >
          </vxe-table-column>
            <vxe-table-column v-else-if="column.type"
            :key="`${index}--${column.prop}`"
            :fixed="column.fixed"
            :title="column.label || ''"
            :type="column.type"
            :width="column.width||null"
            :align="column.align||'center'"
            :min-width="column.minWidth||''"
          />
          <vxe-table-column v-else
            :key="`${index}--${column.prop}`"
            :formatter="getColumnFormatter(column)"
            :field="column.prop"
            :fixed="column.fixed"
            :title="column.label"
            :show-overflow='column.tooltip'
            :min-width="column.minWidth || (column.label?`${column.label.length*16+20}px`:null)"
            :width="column.width||null"
            :align="column.align||'center'"
            :sortable="column.sortable"
          >
            <template v-if="column.render"   #default="{ row, rowIndex}">
              <custom-column
                  :key="rowIndex+column.label+column.prop+row[rowKey]"
                  :row="row"
                  :fixed="column.fixed"
                  :index="rowIndex "
                  :column="column"
                  :renderContent="column.render"
              />
            </template>
          </vxe-table-column>
        </template> -->
        <template #pager>
          <vxe-pager
            :size="size"
            v-if="showPager && pagination && pagination.total"
            :current-page="pagination.currentPage"
            @page-change="handleCurrentChanged"
            :page-sizes="[10,20, 50, 100, 200]"
            :page-size="pagination.pageSize"
            :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
            :total="pagination.total">
        </vxe-pager>
        </template>
    </vxe-grid>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'TableWithPaginationn',
  props: {
    pagination: {
      type: Object,
      default () {
        return {};
      },
    },
    columnList: {
      type: Array,
      default () {
        return [];
      },
    },
    tableData: {
      type: Array,
      default () {
        return [];
      },
    },
    sortInfo: {
      type: Object,
      validator: function (value) {
        if (value) {
          // key必须包含orderByProperty和orderByType
          return 'orderByProperty' in value && 'orderByType' in value;
        }
        return true;
      },
    },
    rowKey: {
      type: String,
      default: 'id',
    },
    defaultSelected: {
      type: Array,
      default: () => [],
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [Number, String],
    },
    maxHeight: {
      type: [Number, String],
    },
    size: {
      type: String,
      default: 'small',
    },
    showPager: {
      type: Boolean,
      default: true,
    },
    checkboxConfig: {
      type: Object,
    },
    radioConfig: {
      type: Object,
    },
    scrollY: {
      type: Object,
      default: () => ({
        enabled: true,
        mode: 'default',
        gt: 30,
      }),
    },
    leftToolbar: {
      type: Array,
      default: () => [],
    },
    showRightToolbar: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      customConfig: {
        storage: false,
        checkMethod: ({column}) => {
          return !column.params || !column.params.isActionColumn;
        },
      },
    };
  },
  computed: {
    ...mapGetters(['actionPermissionMap']),
    toolbarConfig () {
      let buttons = this.leftToolbar.filter(t => t.type !== 'custom' && (!t.permission || this.actionPermissionMap[t.permission]));
      if (buttons.length > 0 || this.showRightToolbar) {
        return {
          import: false,
          export: false,
          zoom: this.showRightToolbar,
          custom: this.showRightToolbar,
          slots: {
            buttons: ({ x }, h) => { // eslint-disable-line
              let custom = this.leftToolbar.filter(t => t.type === 'custom').map(t => t.render(h));
              return buttons.map(t => h('el-button', {
                props: {
                  type: t.status,
                  size: t.size,
                  icon: t.icon,
                  disabled: t.disabled,
                },
                on: {
                  click: t.click,
                },
              }, t.name)).concat(custom);
            },
          },
        };
      } else {
        return null;
      }
    },
    defaultSort () {
      if (this.sortInfo) {
        return {
          trigger: 'cell',
          defaultSort: {
            field: this.sortInfo.orderByProperty,
            order: this.sortInfo.orderByType && this.sortInfo.orderByType.toUpperCase() === 'DESC' ? 'desc' : 'asc',
          },
          orders: ['desc', 'asc', null],
          remote: true,
        };
      } else {
        return {};
      }
    },
    seqConfig () {
      return {
        startIndex: 0,
        seqMethod: ({rowIndex}) => {
          if (this.showPager && this.pagination && Object.keys(this.pagination).length > 0) {
            return (this.pagination.currentPage - 1) * this.pagination.pageSize + rowIndex + 1;
          } else {
            return rowIndex + 1;
          }
        }};
    },
    columns () {
      return this.columnList.map(t => {
        if (t.type) {
          return {
            ...t,
            field: t.prop,
            title: t.label,
            showOverflow: t.tooltip,
            align: t.align || 'center',
            // minWidth: t.minWidth || (t.label ? `${t.label.length * 16 + 20}px` : null),
            width: '120px',
            formatter: this.getColumnFormatter,
            type: t.type === 'index' ? 'seq' : t.type,
          };
        } else if (t.render) {
          return {
            ...t,
            field: t.prop,
            title: t.label,
            align: t.align || 'center',
            showOverflow: t.tooltip,
            minWidth: t.minWidth || (t.label ? `${t.label.length * 16 + 20}px` : null),
            slots: {
              default: ({row, rowIndex, column}, h) => [t.render(h, {row, column, index: rowIndex})],
            },
            params: {
              isActionColumn: true,
            },
          };
        } else {
          return {
            ...t,
            field: t.prop,
            title: t.label,
            align: t.align || 'center',
            showOverflow: t.tooltip,
            minWidth: t.minWidth || (t.label ? `${t.label.length * 16 + 20}px` : null),
            formatter: this.getColumnFormatter(t),
          };
        }
      });
    },
  },
  methods: {
    clearCheckboxReserve () {
      return this.$refs.vxeTable.clearCheckboxReserve();
    },
    clearCheckboxRow () {
      return this.$refs.vxeTable.clearCheckboxRow();
    },
    setCheckboxRow (rows, checked) {
      return this.$refs.vxeTable.setCheckboxRow(rows, checked);
    },
    handleToolbarButtonClick ({code, button, $event}) {
      if (this.leftToolbar) {
        let btnConfig = this.leftToolbar.find(t => t.code === code);
        btnConfig.click && btnConfig.click();
      }
      this.$emit('toolbar-button-click', code, button, $event);
    },
    handleCheckboxChanged ({ checked, row }) {
      this.$emit('select', {row, checked});
      this.handleSelectionChange();
    },
    handleCheckboxAllChecked ({records, checked}) {
      this.$emit('select-all', {selections: records, checked});
      this.handleSelectionChange();
    },
    handleSelectionChange () {
      let selections = this.$refs.vxeTable.getCheckboxRecords();
      if (this.checkboxConfig && this.checkboxConfig.reserve) {
        selections = selections.concat(this.$refs.vxeTable.getCheckboxReserveRecords());
      }
      this.$emit('selection-change', selections);
    },
    handleSortChanged ({order, property}) {
      if (order) {
        order = order.indexOf('asc') >= 0 ? 'ASC' : 'DESC';
      } else {
        property = null;
      }
      this.$emit('update:sortInfo', {orderByProperty: property, orderByType: order});
      this.$emit('sort-change', property, order);
    },
    handleCurrentChanged ({ currentPage, pageSize }) {
      let {total} = this.pagination;
      this.$emit('update:pagination', {total, pageSize, currentPage});
      this.$emit('current-change', {pageSize, currentPage});
    },
    indexMethod (index) {
      let { currentPage, pageSize } = this.pagination;
      return (currentPage - 1) * pageSize + index + 1;
    },
    getColumnFormatter (column) {
      let emptyPlaceholder = ({ cellValue }) => { return cellValue === null || cellValue === undefined || cellValue === '' ? '--' : cellValue; };
      if (column.format) {
        return ({ cellValue }) => {
          let formatValue = column.format(cellValue);
          return emptyPlaceholder({cellValue: formatValue});
        };
      } else {
        return emptyPlaceholder;
      }
    },
  },
  components: {
    // CustomColumn: {
    //   props: {
    //     row: Object,
    //     renderContent: Function,
    //     index: Number,
    //     column: {
    //       type: Object,
    //       default: null,
    //     },
    //   },
    //   render: function (h) {
    //     const params = {
    //       row: this.row,
    //       column: this.column,
    //       index: this.index,
    //     };
    //     return this.renderContent(h, params);
    //   },
    // },
  },
  deactivated () {
    // 组件失活时，关闭tooltip
    this.$refs.vxeTable.closeTooltip();
  },
};
</script>

<style scoped lang="scss">
/deep/.vxe-grid--pager-wrapper {
    margin-top: 16px;
}

/deep/ .vxe-buttons--wrapper {
  &> *:not(:first-child) {
    margin-left: 8px;
  }
}
/deep/ .vxe-cell span.blue{
  color: rgba(15, 137, 255, 0.7);
  cursor: pointer;
}
/deep/ .el-button i +  span{
  margin-left: 0.5em;
}
/deep/ .vxe-cell>div>.blue:not(:first-child) {
  margin-left: 10px;
}
</style>
