// import LoginApi from '@/api/login';
// import { parseMenuTree } from '@/utils/menuTree';
// export default {
//   getMenuInfo ({ commit }) {
//     return LoginApi.getTreeMenuListWithAuth().then(res => {
//       let {menuTree, menuIdMap, menuUrlMap, actionPermissionMap} = parseMenuTree(res.body);
//       commit('UPDATE_MENU_TREE', menuTree);
//       commit('UPDATE_MENU_ID_MAP', menuIdMap);
//       commit('UPDATE_MENU_URL_MAP', menuUrlMap);
//       commit('UPDATE_ACTION_PERMISSION', actionPermissionMap);
//       return res.body;
//     });
//   },
// };
export default {

};
