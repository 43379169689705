import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/element';
import './plugins/vxe-table';
import './plugins/router-tab';

import Util from '@/utils/util';
import '@/assets/iconfont/iconfont.css';
import '@/assets/iconfont/iconfont.js';
import '@/assets/style/index.scss';

import filters from '@/filters';
import directives from '@/directives';

Vue.config.productionTip = false;
Vue.use(Util);
Vue.use(filters);
Vue.use(directives);

Vue.prototype.$eventBus = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
